import React from 'react';
import { FORM_COMPANY_SALES } from '../../constants/form/form';
import { COLUMN } from './constants/column';
import TabLayout from '../../components/tab-layout';
import ManageModal from '../../components/manage-modal';
import useCompanySalesState from '../../hooks/use-company-sales-state';
import { mapSaleName } from '../../../telesales/logic';
import { ButtonTheme } from '../../../../components/buttons';
import CompanyAssignModal from '../../components/company-assign-modal';
import { useRemoveCompanyTeleSale, useUpdateAdminSale } from '../../hooks/use-comapny-sales-query';
import { useSelector } from 'react-redux';
import { openAlert } from '../../../../components/alert/useAlert';
import { Icon } from '../../../../resources';

function CompanySales() {
  const { user } = useSelector((state) => state.authen);
  const { tableProps, selectProps, paginationProps, options, onSave, onSearch, onClearCustom } =
    useCompanySalesState();
  const [modalProps, setModalProps] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [openAssignModal, setAssignModal] = React.useState(false);
  const updateCompanySale = useUpdateAdminSale();
  const removeCompanyTelesale = useRemoveCompanyTeleSale();
  const isSuperAdmin = user?.role === 'SUPER_ADMIN';

  const onToggleRow = (details) => {
    setModalProps({
      title: `จัดการสิทธิ์ ${details?.name}`,
      defaultValues: details?.code ? details?.code?.map((v) => ({ id: v, value: v })) : [],
      id: details?.id,
    });
    setOpen(!open);
  };

  const onOpenModal = () => {
    setAssignModal(true);
  };

  const onCloseModal = () => {
    setAssignModal(false);
  };

  const onSaveAssignMultiple = async (body) => {
    await updateCompanySale.mutateAsync(body);
  };

  const removeCompanyOwner = async (row) => {
    openAlert({
      model: 'confirm',
      title: 'ต้องการนำบริษัทที่คุณดูแลอยู่ออก ใช่หรือไม่',
      okText: 'นำออก',
      cancelText: 'ยกเลิก',
      onOk: async () => {
        const body = {
          id: row.id,
        };
        await removeCompanyTelesale.mutateAsync(body);
      },
    });
  };

  return (
    <>
      <TabLayout
        searchLayoutProps={{
          title: 'ตัวกรอง',
          isEnableEnterSubmit: true,
          formSearch: FORM_COMPANY_SALES({ options }),
          onSearch: onSearch,
          onClearCustom: onClearCustom,
        }}
        tableProps={{
          size: 'small',
          columns: COLUMN({
            pageStep: paginationProps?.pageStep,
            onToggleRow,
            saleCodeOptions: options?.saleCode,
            role: user?.role,
            removeCompanyOwner,
          }),
          scroll: { x: 'max-content' },
          style: { width: '100%' },
          contentsExtra: isSuperAdmin && (
            <div style={{ width: '100%', textAlignLast: 'end' }}>
              <ButtonTheme
                useFor="CUSTOM"
                title="Multiple Assign"
                icon={<Icon.userOutline />}
                type="primary"
                onClick={() => onOpenModal()}
              />
            </div>
          ),
          ...tableProps,
        }}
        paginationProps={paginationProps}
      />
      <ManageModal
        open={open}
        onToggle={() => setOpen((prevOpen) => !prevOpen)}
        {...modalProps}
        onSave={onSave}
        selectProps={{
          selectValueKey: 'id',
          placeholder: 'เลือกรหัสเซล',
          mappingData: (values) => ({
            id: values.id,
            value: values.value,
            label: values.label,
          }),
          ...selectProps,
        }}
        renderRow={(item) => <span>{mapSaleName(item.value, options?.saleCode)}</span>}
      />
      <CompanyAssignModal
        open={openAssignModal}
        options={options}
        onClose={onCloseModal}
        onSave={onSaveAssignMultiple}
        selectProps={{
          selectValueKey: 'id',
          placeholder: 'เลือกรหัสเซล',
          mappingData: (values) => ({
            id: values.id,
            value: values.value,
            label: values.label,
          }),
          ...selectProps,
        }}
      />
    </>
  );
}

export default CompanySales;
