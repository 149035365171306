import React from 'react';
import TableCustom from '../../../../../telesales/components/table-custom';
import { CompanyAssignColumns } from '../../constants';

function AssignPreviewTable({ assignCompanyListLoading, onRemoveItem, dataSource }) {
  return (
    <>
      <p>รายชื่อบริษัทที่ Assign</p>
      <TableCustom
        loading={assignCompanyListLoading}
        size="small"
        scroll={{
          x: 'max-content',
        }}
        columns={CompanyAssignColumns({ onDelete: onRemoveItem })}
        dataSource={dataSource}
        hasPagination={true}
        sticky={true}
        isShowNo
      />
    </>
  );
}

export default AssignPreviewTable;
