import { createContext } from 'preact';
import React, { useReducer, useContext } from 'react';
import ContactModal from '../views/contact-modal';
import HistoryCallModal from '../views/history-call-modal';
import EditCallInformationModal from '../views/edit-call-info-modal';
import PackageItemsModal from '../views/package-items-modal';
import EditContactModal from '../views/edit-contact-modal';
import CallModal from '../views/call-modal';
import AddModal from '../views/add-contact-modal';
import NoteModal from '../views/note-modal';
import QuotationModal from '../views/quotation-modal';
import ApplicantCountModal from '../views/applicant-count-modal';

export const useModalContext = () => useContext(ModalContext);

const initialState = {
  isOpen: false,
  modalType: null,
  modalProps: {},
  modalHistory: [],
};

const modalReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        isOpen: true,
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps || {},
        modalHistory: [
          ...state.modalHistory,
          { modalType: state.modalType, modalProps: state.modalProps },
        ],
      };

    case 'CLOSE_MODAL':
      return initialState;

    case 'GO_BACK':
      const previousModal = state.modalHistory[state.modalHistory.length - 1];
      return {
        ...state,
        modalType: previousModal.modalType,
        modalProps: previousModal.modalProps,
        modalHistory: state.modalHistory.slice(0, -1),
      };

    default:
      return state;
  }
};

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, initialState);

  const openModal = (modalType, modalProps) => {
    dispatch({ type: 'OPEN_MODAL', payload: { modalType, modalProps } });
  };

  const closeModal = () => {
    dispatch({ type: 'CLOSE_MODAL' });
  };

  const goBack = () => {
    dispatch({ type: 'GO_BACK' });
  };

  const renderModalContent = () => {
    const modalProps = {
      open: state.isOpen,
      onToggle: goBack,
      onGoBack: goBack,
      ...state.modalProps,
    };

    switch (state.modalType) {
      case 'CONTACT_MODAL':
        return <ContactModal {...modalProps} />;
      case 'HISTORY_CALL_MODAL':
        return <HistoryCallModal {...modalProps} />;
      case 'EDIT_CALL_INFOMATION_MODAL':
        return <EditCallInformationModal {...modalProps} />;
      case 'PACKAGE_ITEMS_MODAL':
        return <PackageItemsModal {...modalProps} />;
      case 'APPLICANTS_COUNT_MODAL':
        return <ApplicantCountModal {...modalProps} />;
      case 'EDIT_CONTACT_MODAL':
        return <EditContactModal {...modalProps} />;
      case 'ADD_CONTACT_MODAL':
        return <AddModal {...modalProps} />;
      case 'CALL_MODAL':
        return <CallModal {...modalProps} />;
      case 'NOTE_MODAL':
        return <NoteModal {...modalProps} />;
      case 'QUOTATION_MODAL':
        return <QuotationModal {...modalProps} />;
      default:
        return null;
    }
  };

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
        goBack,
      }}
    >
      {children}
      {renderModalContent()}
    </ModalContext.Provider>
  );
};
