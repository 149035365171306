import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as callApi from '../services';
import { closeLoading, openAlert, openLoading } from '../../../components/alert/useAlert';

export const useStartCall = () => {
  const mutation = useMutation({
    mutationKey: 'startCall',
    mutationFn: (reqBody) => {
      return callApi.startCall(reqBody);
    },
    onError: () => {
      openAlert({
        type: 'error',
        message: 'เกิดข้อผิดพลาด',
      });
    },
  });

  return mutation;
};

export const useEndCall = () => {
  const mutation = useMutation({
    mutationKey: 'startCall',
    mutationFn: (params) => callApi.endCall({ ...params }),
    onError: () => {
      openAlert({
        type: 'error',
        message: 'เกิดข้อผิดพลาด',
      });
    },
  });

  return mutation;
};

export const useCreateCallTransaction = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['createCallTransaction'],
    mutationFn: (reqBody) => {
      openLoading();
      return callApi.createTransaction(reqBody);
    },
    onSuccess: (r) => {
      closeLoading();
      openAlert({
        type: 'success',
        message: 'บันทึกการโทรสำเร็จ',
      });
      queryClient.invalidateQueries(['tabledata']);
      queryClient.invalidateQueries(['historyContactData', r?.result?.customer_id]);
    },
    onError: () => {
      closeLoading();
      openAlert({
        type: 'error',
        message: 'เกิดข้อผิดพลาด',
      });
    },
  });

  return mutation;
};

export const useCheckOnCall = () => {
  const mutate = useMutation({
    mutationKey: ['onCall'],
    mutationFn: (id) => {
      openLoading();
      return callApi.checkOnCall(id);
    },
    onSettled: () => {
      closeLoading();
    },
  });

  return mutate;
};

export const useDestoryTrxCall = () => {
  const mutate = useMutation({
    mutationKey: ['destroyTrxCall'],
    mutationFn: () => {
      openLoading();
      return callApi.cancelTrxCall();
    },
    onSettled: () => {
      closeLoading();
    },
  });

  return mutate;
};
