import { DELETE, GET, GET_WITH_PARAMS, POST } from '../../../services';

export const getCompanySales = async (filters) => {
  try {
    const response = await GET_WITH_PARAMS('/admin/get-company-assign', filters);
    return response?.result;
  } catch (err) {
    throw new Error('error');
  }
};

export const updateCompanySale = async (body) => {
  try {
    const response = await POST('/admin/assign-code-company', body);
    return response;
  } catch (err) {
    throw new Error(err?.message || 'error');
  }
};

export const removeCompanyTelesaleRole = async (body) => {
  try {
    const response = await DELETE(`/admin/sale/delete/${body.id}`);
    return response;
  } catch (err) {
    throw new Error(err?.message || 'error');
  }
};

export const getCompanyListBySalecode = async (body) => {
  try {
    const response = await GET(`/admin/company/list/${body?.code}`);
    return response;
  } catch (err) {
    throw new Error(err?.message || 'error');
  }
};
