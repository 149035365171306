export const renderJobStatus = (status) => {
  switch (status) {
    case 'online':
      return {
        textTh: 'เปิดรับสมัคร',
        color: 'green',
      };
    case 'close':
      return {
        textTh: 'ปิดรับสมัคร',
        color: 'red',
      };
    default:
      return null;
  }
};

export const renderPackageType = (type) => {
  switch (type) {
    case 'FREE':
      return {
        textTh: 'Free',
        color: 'gold',
      };
    case 'BUY':
      return {
        textTh: 'Buy',
        color: 'purple',
      };
    default:
      return null;
  }
};
