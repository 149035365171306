import React from 'react';
import { Divider } from 'antd';
import { column } from '../../constants/column';
import TableCustom from '../../../../components/table-custom';
import Information from '../../../../components/information';
import { usePackageList } from '../../../../hooks/use-table-query';

function PackageItemContents(props) {
  const { companyDetails, isFromCallingModal } = props;
  const { data, isLoading } = usePackageList(companyDetails?._id);

  return (
    <>
      {!isFromCallingModal && (
        <>
          <Information {...companyDetails} />
          <Divider />
        </>
      )}

      <TableCustom
        loading={isLoading}
        scroll={{ x: 'max-content' }}
        columns={column()}
        dataSource={data?.result?.data || []}
        hasPagination={true}
        isShowNo
      />
    </>
  );
}

export default PackageItemContents;
