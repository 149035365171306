import React from 'react';
import { ButtonTheme } from '../../../../components/buttons';
import { ModalTheme } from '../../../../components/modal';
import { color } from '../../../../resources';
import { Col, Divider, Space, Row, Tabs } from 'antd';
import ContactInformation from './views/contact-information';
import ContactForm from './views/contact-form';
import CallTimer from './views/call-timer';
import useCallAction, { CALL_STATUS } from '../../hooks/use-call-action';
import { useFormState } from './views/contact-form/hooks/useFormState';
import { useOptionsContext } from '../../contexts/options-context';
import { displayTabs } from './constants';
import { resetPasswordCompany } from './service/reset-password';

function CallModal(props) {
  const { companyId, contactDetails, companyDetails, onCallDetails, lastNote, ...modalProps } =
    props;
  const { options } = useOptionsContext();
  const resetPassword = resetPasswordCompany();

  const onClose = () => {
    props?.onToggle({ defaultValues: {} });
  };

  const { formProps, callStatusProps, onSubmit } = useFormState({
    companyDetails,
    options,
  });

  const { callTimerProps, onSubmitTransaction } = useCallAction({
    contactId: contactDetails?._id,
    companyId,
    onCallDetails,
    onSubmit,
  });

  const sendMailResetPassword = async () => {
    await resetPassword.mutateAsync(contactDetails?.email);
  };

  return (
    <ModalTheme
      width={'100vw'}
      title="โทรติดต่อ"
      style={{ height: '100vh' }}
      footer={
        <Footer
          isDisabled={callTimerProps?.status !== CALL_STATUS.WAITING_CONFIRM}
          onSubmit={() =>
            onSubmitTransaction({
              onCallBack: onClose,
            })
          }
          onClose={onClose}
        />
      }
      {...modalProps}
    >
      <Row gutter={8}>
        <Col span={12} style={{ paddingRight: 20 }}>
          <CallTimer {...callTimerProps} callStatusProps={callStatusProps} text={lastNote} />
          <ContactForm {...formProps} />
        </Col>
        <Col span={12}>
          <ContactInformation companyDetails={companyDetails} contactDetails={contactDetails} />
          <ButtonTheme
            useFor="CUSTOM"
            title="ส่งเมลรีเซ็ตรหัสผ่าน"
            type="primary"
            color={color.blue80}
            disabled={!contactDetails?.email}
            onClick={sendMailResetPassword}
            style={{ marginTop: '15px' }}
          />
          <Divider />
          <Tabs
            type="card"
            defaultActiveKey="1"
            size={'small'}
            style={{
              marginBottom: 32,
            }}
            items={displayTabs({ companyId, contactId: contactDetails?._id, companyDetails }).map(
              (e) => e,
            )}
          />
        </Col>
      </Row>
    </ModalTheme>
  );
}

function Footer({ isDisabled, onSubmit, onClose }) {
  return (
    <Space>
      <ButtonTheme
        useFor="CUSTOM"
        title="ยกเลิก"
        type="primary"
        color={color.grey400}
        onClick={onClose}
      />
      <ButtonTheme
        useFor="CUSTOM"
        title="บันทึก"
        type="primary"
        disabled={isDisabled}
        color={color.blue80}
        onClick={onSubmit}
      />
    </Space>
  );
}

export default CallModal;
