import React from 'react';
import HistoryCallContents from '../../history-call-modal/views/history-call-contents';
import PackageItemContents from '../../package-items-modal/views/package-item-contents';
import ApplicantCountContents from '../../applicant-count-modal/views/applicant-count-contents';

export const displayTabs = ({ companyId, contactId, companyDetails }) => [
  {
    label: `ยอดผู้สมัคร`,
    key: 1,
    children: <ApplicantCountContents companyDetails={companyDetails} isFromCallingModal={true} />,
  },
  {
    label: `ประวัติการโทร`,
    key: 2,
    children: <HistoryCallContents companyId={companyId} contactId={contactId} />,
  },
  {
    label: `Package/Item`,
    key: 3,
    children: <PackageItemContents companyDetails={companyDetails} isFromCallingModal={true} />,
  },
];
