import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import * as companySalesApi from '../services';
import { closeLoading, openAlert, openLoading } from '../../../components/alert/useAlert';
import { getErrorMsg, getSuccessMsg } from '../../../utils/api/msg';

export const useCompanySaleTableQuery = (filters) => {
  return useQuery({
    queryKey: ['companySale', filters],
    queryFn: () => companySalesApi.getCompanySales(filters),
    staleTime: 0,
  });
};

export const useUpdateAdminSale = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: 'updateCompanySale',
    mutationFn: (body) => {
      openLoading();
      return companySalesApi.updateCompanySale(body);
    },
    onSuccess: (res) => {
      openAlert({
        type: 'success',
        message: getSuccessMsg(res?.message),
      });
      queryClient.invalidateQueries(['companySale']);
      queryClient.invalidateQueries(['tabledata']);
    },
    onError: (error) => {
      openAlert({
        type: 'error',
        message: getErrorMsg(error?.message),
      });
    },
    onSettled: () => {
      closeLoading();
    },
  });
};

export const useRemoveCompanyTeleSale = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: 'removeCompanySale',
    mutationFn: (body) => {
      openLoading();
      return companySalesApi.removeCompanyTelesaleRole(body);
    },
    onSuccess: (res) => {
      openAlert({
        type: 'success',
        message: getSuccessMsg(res?.message),
      });
      queryClient.invalidateQueries(['companySale']);
      queryClient.invalidateQueries(['tabledata']);
    },
    onError: (error) => {
      openAlert({
        type: 'error',
        message: getErrorMsg(error?.message),
      });
    },
    onSettled: () => {
      closeLoading();
    },
  });
};
