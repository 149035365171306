import { useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import { getCompanyListBySalecode } from '../../../services';

const useSelectItems = ({ open }) => {
  const [selectItems, setSelectItems] = useState(null);

  const { data: assignCompanyList, isFetching: assignCompanyListLoading } = useQuery({
    queryKey: ['companyBySalecode', selectItems],
    queryFn: () => getCompanyListBySalecode({ code: selectItems }),
    staleTime: 0,
    enabled: !!selectItems,
    initialData: null,
    retry: 0,
  });

  useEffect(() => {
    if (open) {
      setSelectItems(null);
    }
  }, [open]);

  const onSelect = async (value) => {
    setSelectItems(value);
  };

  return {
    isEdited: selectItems,
    selectValues: selectItems || null,
    onSelect,
    assignCompanyList: assignCompanyList?.result?.data || [],
    assignCompanyListLoading,
  };
};

export default useSelectItems;
