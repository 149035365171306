import React from 'react';
import { ModalTheme } from '../../../../components/modal';
import { ButtonTheme } from '../../../../components/buttons';
import { color } from '../../../../resources';
import Row from '../../../../components/row';

import PackageItemContents from './views/package-item-contents';

function PackageItemsModal(props) {
  return (
    <ModalTheme
      width={1000}
      title="Package / Item ทั้งหมด"
      {...props}
      footer={<Footer onClose={props.onToggle} />}
    >
      <PackageItemContents {...props} />
    </ModalTheme>
  );
}

function Footer({ onClose }) {
  return (
    <Row justifyContent="flex-end" alignItems="center">
      <ButtonTheme
        useFor="CUSTOM"
        title="ปิด"
        type="primary"
        color={color.blue80}
        onClick={onClose}
      />
    </Row>
  );
}

export default PackageItemsModal;
