import styled from 'styled-components';
import { color } from '../../../../../../resources';

export const TextWrapper = styled.div`
  width: 100%;
  height: 70px;
  overflow-y: auto;
  padding: 8px;
  border: 1px solid ${color.grey10};
  border-radius: 6px;
  margin-bottom: 2px;
`;
