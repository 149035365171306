import { useDeleteAdminSale } from '../../../hooks/use-admin-sales-query';

export const useDeleteSale = () => {
  const deleteSale = useDeleteAdminSale();
  const handleDeleteSale = async ({ code }) => {
    await deleteSale.mutateAsync(code);
  };

  return {
    handleDeleteSale,
  };
};
