import React, { useState } from 'react';
import { Select } from 'antd';
import { column } from '../../constants/column';
import TableCustom from '../../../../components/table-custom';
import { ScrollBox, HeaderBox, Container } from '../../../../styled-component';
import { useContactCompanyQuery } from '../../../../hooks/use-contact-query';
import { generateContactOptions } from '../../logic';
import {
  useHistoryCompanyListQuery,
  useHistoryContactListQuery,
} from '../../../../hooks/use-history-call-query';

function HistoryCallContents(props) {
  const { contactId, companyId } = props;
  const { data: fetchContacts, isLoading: isContactLoading } = useContactCompanyQuery(companyId);
  const options = generateContactOptions(fetchContacts, companyId);

  const [selectedOption, setSelectedOption] = useState(contactId || companyId || 'ทั้งหมด');

  const { data, isLoading } =
    companyId === selectedOption
      ? useHistoryCompanyListQuery(selectedOption)
      : useHistoryContactListQuery(selectedOption);
  return (
    <Container>
      <HeaderBox height={32}>
        <Select
          loading={isContactLoading}
          defaultValue={selectedOption}
          style={{ width: 120 }}
          options={options}
          onChange={(e) => setSelectedOption(e)}
        />
      </HeaderBox>

      <ScrollBox height={32}>
        <TableCustom
          loading={isLoading}
          scroll={{
            x: 'max-content',
          }}
          columns={column()}
          dataSource={data}
          hasPagination={true}
          sticky={true}
          isShowNo
        />
      </ScrollBox>
    </Container>
  );
}

export default HistoryCallContents;
