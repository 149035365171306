import React from 'react';
import { Tag } from 'antd';
import { fullThaiDateShortMonth } from '../../../../../utils/date-time';
import { renderJobStatus, renderPackageType } from '../functions';
import { EmptyColumn } from '../../table/components/render-column';

export const applicantColumns = () => [
  {
    title: 'Package',
    dataIndex: 'package',
    key: 'package',
    align: 'center',
    width: 130,
    render: (text) => {
      const packageTypeObj = renderPackageType(text);
      return packageTypeObj ? (
        <Tag color={packageTypeObj.color}>{packageTypeObj.textTh}</Tag>
      ) : (
        <EmptyColumn />
      );
    },
  },
  {
    title: 'ชื่อประกาศงาน',
    dataIndex: 'jobName',
    key: 'jobName',
    width: 130,
  },
  {
    title: 'วันที่โพสต์',
    dataIndex: 'postDate',
    key: 'postDate',
    width: 120,
    render: (text) => {
      return fullThaiDateShortMonth(text);
    },
  },
  {
    title: 'วันที่อัปเดตล่าสุด',
    dataIndex: 'updateDate',
    key: 'updateDate',
    width: 130,
    render: (text) => {
      return fullThaiDateShortMonth(text);
    },
  },
  {
    title: 'สถานะ',
    dataIndex: 'status',
    key: 'status',
    width: 150,
    render: (text) => {
      const jobStatusObj = renderJobStatus(text);
      return jobStatusObj ? (
        <Tag color={jobStatusObj.color}>{jobStatusObj.textTh}</Tag>
      ) : (
        <EmptyColumn />
      );
    },
  },
  {
    title: 'จำนวนผู้สมัคร',
    dataIndex: 'applyCount',
    key: 'applyCount',
    width: 120,
    render: (text) => {
      return `${text} คน`;
    },
  },
];
