import { Col, Divider, Row, Select, Space } from 'antd';
import React from 'react';
import { ButtonTheme } from '../../../../../../components/buttons';
import { color } from '../../../../../../resources';

function AssignForm({
  options,
  selectValues,
  onSelect,
  resetForm,
  companyValues,
  onSelectCompany,
  addCompanyAssign,
}) {
  return (
    <>
      <div>
        <Space direction="vertical" style={{ width: '100%' }}>
          เลือกเซลที่ต้องการ assign
          <Select
            allowClear
            placeholder="เลือกเซล"
            showSearch
            options={options?.saleCode}
            style={{
              width: '50%',
            }}
            value={selectValues}
            onChange={onSelect}
            onClear={resetForm}
            filterOption={(inputValue, option) => {
              return option?.label.toLowerCase().includes(inputValue.toLowerCase());
            }}
            maxTagCount="responsive"
          />
        </Space>
        <div style={{ padding: '10px 0' }}>
          เลือกบริษัทที่จะให้เซลดูแล
          <Row style={{ alignItems: 'center' }}>
            <Col span={20}>
              <Select
                showSearch
                allowClear
                placeholder="เลือกบริษัท"
                disabled={!selectValues}
                options={options?.companyNameOptions}
                mode="multiple"
                style={{
                  width: '100%',
                }}
                value={companyValues}
                onChange={onSelectCompany}
                filterOption={(inputValue, option) => {
                  return option?.label.toLowerCase().includes(inputValue.toLowerCase());
                }}
                maxTagCount="responsive"
              />{' '}
            </Col>
            <Col span={4}>
              <ButtonTheme
                type="primary"
                color={color.blue80}
                onClick={addCompanyAssign}
                disabled={!selectValues || companyValues.length === 0}
                useFor="custom"
                title="เพิ่ม"
              />
            </Col>
          </Row>
        </div>
      </div>
      <Divider />
    </>
  );
}

export default AssignForm;
