import React from 'react';
import { Divider } from 'antd';
import { applicantColumns } from '../../constants/column';
import TableCustom from '../../../../components/table-custom';
import Information from '../../../../components/information';
import { useApplyCountDetail } from '../../../../hooks/use-table-query';

function ApplicantCountContents(props) {
  const { companyDetails, isFromCallingModal } = props;
  const { data, isLoading } = useApplyCountDetail(companyDetails?._id);

  return (
    <>
      {!isFromCallingModal && (
        <>
          <Information {...companyDetails} />
          <Divider />
        </>
      )}
      <p style={{ justifySelf: 'right' }}>ยอดผู้สมัครทั้งหมด: {data?.result?.total || 0} คน</p>
      <TableCustom
        loading={isLoading}
        scroll={{ x: 'max-content' }}
        columns={applicantColumns()}
        dataSource={data?.result?.data || []}
        hasPagination={true}
        isShowNo
      />
    </>
  );
}

export default ApplicantCountContents;
