import * as envInit from '../../env.json';

export const env = {
  web_title: envInit.WEB_TITLE,
  url: envInit.API_URL,
  admin_web: envInit.ADMIN_HOST,
  company_web: envInit.COMPANY_HOST,
  client_web: envInit.CLIENT_HOST,
  node_env: envInit.NODE_ENV,
  port: envInit.PORT,
};
