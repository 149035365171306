import { Col, Row } from 'antd';
import React from 'react';
import { INFORMATION_HEADER } from './constants/info-header';
import { Icon } from '../../../../../../resources';
import { getBadgeColor } from '../../../../../../utils/company';
import { ContactName } from '../../../contact-modal/components/render-column';

function ContactInformation(props) {
  const { companyDetails, contactDetails } = props;

  return (
    <div>
      <Row gutter={[16, 8]} align="middle">
        <Col span={8}>
          ชื่อบริษัท:{' '}
          {companyDetails?.company?.name ? (
            <div style={{ display: 'inline' }}>
              {companyDetails?.company?.name}{' '}
              <Icon.checkCircle
                style={{
                  verticalAlign: 'middle',
                  color: getBadgeColor(companyDetails?.company?.status),
                }}
              />
            </div>
          ) : (
            '-'
          )}
        </Col>
        <Col span={8}>
          <ContactName
            data={contactDetails?.name}
            records={contactDetails}
            align="left"
            hasTitle={true}
          />
        </Col>
        <Col span={8}>ตำแหน่ง: {contactDetails?.position || '-'}</Col>
        <Col span={8}>เบอร์โทรศัพท์: {contactDetails?.phone || '-'}</Col>
        <Col span={12}>อีเมล: {contactDetails?.email || '-'}</Col>
      </Row>
    </div>
  );
}

export default ContactInformation;
