import React, { useEffect, useState } from 'react';
import { ButtonTheme } from '../../../../components/buttons';
import { ModalTheme } from '../../../../components/modal';
import { openAlert } from '../../../../components/alert/useAlert';
import { color } from '../../../../resources';
import useSelectItems from './hooks/use-select-items';
import AssignForm from './views/assign-form';
import AssignPreviewTable from './views/assign-preview-table';

function CompanyAssignModal(props) {
  const { selectProps, onSave, open, options } = props;
  const { assignCompanyListLoading, selectValues, isEdited, onSelect, assignCompanyList } =
    useSelectItems({
      defaultValues: props.defaultValues,
      open: props?.open,
      mappingData: selectProps.mappingData,
      selectValueKey: selectProps?.selectValueKey,
    });
  const [companyValues, setCompanyValues] = useState([]);
  const [companyIDValues, setCompanyIDValues] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (Array.isArray(assignCompanyList)) {
      const formatData = assignCompanyList.map((e) => {
        return { name: e.name, value: e.id };
      });
      const formatDataID = assignCompanyList.map((e) => {
        return e.id;
      });
      setDataSource(formatData);
      setCompanyIDValues(formatDataID);
    }
  }, [assignCompanyList]);

  const onClose = () => {
    if (isEdited) {
      openAlert({
        model: 'confirm',
        title: 'คุณต้องการยกเลิกการทำรายการ ?',
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
        onOk: () => {
          props.onClose();
          resetForm();
        },
      });
      return;
    }
    props.onClose();
    resetForm();
  };

  const resetForm = () => {
    setDataSource([]);
    setCompanyIDValues([]);
    setCompanyValues([]);
  };

  const onClickSave = () => {
    openAlert({
      model: 'confirm',
      title: 'คุณต้องการบันทึกรายการใช่หรือไม่',
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
      onOk: () => {
        const body = {
          code: [selectValues],
          companyIds: companyIDValues,
        };
        onSave(body).then(() => {
          props.onClose();
          resetForm();
        });
      },
    });
  };

  const onRemoveItem = (row) => {
    let remainIDs = [];
    const remainItems = dataSource.filter((v) => {
      if (v.value !== row.value) {
        remainIDs.push(v.value);
        return true;
      }
    });
    setDataSource(remainItems);
    setCompanyIDValues(remainIDs);
  };

  const onSelectCompany = (data, row) => {
    setCompanyValues(row);
    const mergeCompanyID = companyIDValues.concat(data);
    setCompanyIDValues([...new Set(mergeCompanyID)]);
  };

  const addCompanyAssign = () => {
    const result = companyValues.map((v) => {
      return { name: v.label, value: v.value };
    });
    const mergeData = dataSource.concat(result);
    const uniqueArray = mergeData.filter(
      (obj, index, self) => index === self.findIndex((item) => item.value === obj.value),
    );
    setDataSource(uniqueArray);
    setCompanyValues([]);
  };

  return (
    <ModalTheme
      width={1000}
      title="Assign Company"
      open={open}
      onCancel={onClose}
      footer={
        <ButtonTheme
          type="primary"
          color={color.blue80}
          disabled={dataSource.length === 0 || !selectValues}
          onClick={onClickSave}
          useFor="custom"
          title="บันทึก"
        />
      }
    >
      <AssignForm
        options={options}
        selectValues={selectValues}
        onSelect={onSelect}
        resetForm={resetForm}
        companyValues={companyValues}
        onSelectCompany={onSelectCompany}
        addCompanyAssign={addCompanyAssign}
      />
      <AssignPreviewTable
        assignCompanyListLoading={assignCompanyListLoading}
        onRemoveItem={onRemoveItem}
        dataSource={dataSource}
      />
    </ModalTheme>
  );
}

export default CompanyAssignModal;
