import { useMutation } from '@tanstack/react-query';
import * as tableApi from '../../../services';
import { closeLoading, openAlert, openLoading } from '../../../../../components/alert/useAlert';

export const resetPasswordCompany = () => {
  const mutation = useMutation({
    mutationKey: 'resetPasswordCompany',
    mutationFn: (email) => {
      openLoading();
      return tableApi.resetPassword({ email });
    },
    onSuccess: () => {
      closeLoading();
      openAlert({
        type: 'success',
        message: 'ส่งเมลรีเซ็ตรหัสผ่านสำเร็จ',
      });
    },
    onError: () => {
      closeLoading();
      openAlert({
        type: 'error',
        message: 'เกิดข้อผิดพลาด',
      });
    },
  });

  return mutation;
};
