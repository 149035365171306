import TelesalesScene from '../screens/telesales/index.jsx';
import AssignTelesalesScene from '../screens/assign-telesales/index.jsx';

//routes
export const ROUTE_LOGIN = 'https://central.zetta-system.com';
export const ROUTE_HANDLE = '/account/handle';
export const ROUTE_TELESALES = '';
export const ROUTE_ASSIGN_TELESALES = '/assign-telesales';

//keys
export const KEY_ROUTE_MAIN = '@ROUTES/ROUTE_MAIN';
export const KEY_ROUTE_HOME = '@ROUTES/ROUTE_HOME';
export const KEY_ROUTE_DASHBOARD = '@ROUTES/ROUTE_DASHBOARD';
export const KEY_ROUTE_TELESALES = '@ROUTES/ROUTE_TELESALES';
export const KEY_ROUTE_ASSIGN_TELESALES = '@ROUTES/ROUTE_ASSIGN_TELESALES';

export const ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  ACCOUNTING_ADMIN: 'ACCOUNTING_ADMIN',
  TELESALE: 'TELESALE',
  CUSTOMER_SERVICE: 'CUSTOMER_SERVICE',
  ALL: 'ALL',
};

export const ROUTES_PATH = {
  ROUTE_MAIN: {
    KEY: KEY_ROUTE_MAIN,
    PATH: '/',
    LABEL: 'Customer Success',
    COMPONENT: TelesalesScene,
    ACCESS_ROLES: [ROLES.TELESALE, ROLES.ADMIN, ROLES.SUPER_ADMIN, ROLES.CUSTOMER_SERVICE],
  },
  ROUTE_ASSIGN_TELESALES: {
    KEY: KEY_ROUTE_ASSIGN_TELESALES,
    PATH: ROUTE_ASSIGN_TELESALES,
    LABEL: 'Assign Admin',
    COMPONENT: AssignTelesalesScene,
    ACCESS_ROLES: [ROLES.SUPER_ADMIN, ROLES.CUSTOMER_SERVICE, ROLES.TELESALE],
  },
};

function getKeyLowerCase(obj) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k.toLowerCase(), v]));
}

export const ROUTES_MENU = [
  getKeyLowerCase(ROUTES_PATH.ROUTE_MAIN),
  getKeyLowerCase(ROUTES_PATH.ROUTE_ASSIGN_TELESALES),
];
