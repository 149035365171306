const ROLE_CONSTANT = {
  SUPER_ADMIN: {
    label: 'Super admin',
    color: 'red',
  },
  ADMIN: {
    label: 'Admin',
    color: 'blue',
  },
  ACCOUNTING_ADMIN: {
    label: 'Accounting admin',
    color: 'green',
  },
  TELESALE: {
    label: 'Telesale',
    color: 'gold',
  },
  CUSTOMER_SERVICE: {
    label: 'Customer service',
    color: 'yellow',
  },
};

export const getRoleDisplay = (role) => {
  return ROLE_CONSTANT[role]?.label || '-';
};

export const getColorRole = (role) => {
  return ROLE_CONSTANT[role]?.color || '-';
};
