import { useAddSaleCode } from '../../../hooks/use-admin-sales-query';

export const useAddNewSaleCode = () => {
  const newSaleCode = useAddSaleCode();
  const handleAddSaleCode = async (body) => {
    await newSaleCode.mutateAsync(body);
  };

  return {
    handleAddSaleCode,
  };
};
